<template>
  <div class="tradingIndexAndNewsPageBox trading-news-main-box">
    <div class="left">
      <!-- 上面title -->
      <div class="title">
        <h3 class="name">
          <img src="@/assets/img/jyzs.png" alt="icon">平台交易指数
        </h3>
        <div class="timer">
          <span
            v-for="(item, index) in timerArr"
            :key="index"
            :class="[activeIndex === index ? 'active' : '']"
            @click="getIndex(item, index)"
          >{{ item.name }}</span>
        </div>
      </div>
      <!-- 下面折线图 -->
      <div class="lineChart">
        <!-- 上面的品名 -->
        <div class="titles">
          <span
            v-for="(item, index) in pnameArr"
            :key="index"
            :class="[activePname === index ? 'active' : '']"
            @click="getPname(item, index)"
          >{{ item.name }}</span>
        </div>
        <!-- 折线图 -->
        <div id="main" style="height: 290px; width: 100%" />
      </div>
      <!-- 下面的表格 -->
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%" :height="266">
          <el-table-column prop="goodsName" label="品名/材质/型号" width="280" />
          <el-table-column prop="manufactor" label="钢厂">
            <template slot-scope="scope">
              <span class="elp-1">{{ scope.row.manufactor }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="price" align="right" label="价格(元/吨)" />
        </el-table>
      </div>
    </div>
    <div class="right">
      <!-- 上面标题 -->
      <div class="title">
        <h3 class="name">
          <img src="@/assets/img/sheng.png" alt="icon">平台快报
        </h3>
        <router-link :to="{ path: '/news/index' }" class="more">
          更多<img src="@/assets/img/right.png" alt="icon">
        </router-link>
      </div>
      <!-- 下面新闻 -->
      <ul v-if="newsArrList.length>0" class="newsBox">
        <li v-for="(item, index) in newsArrList" :key="index">
          <router-link
            :to="{ path: '/news/newsDetail', query: { id: item.id } }"
            class="newsTitle elp-1"
          >
            <span v-if="item.isTop === 1">置顶</span>{{ item.title }}
          </router-link>
          <i v-if="item.createTime">{{ item.createTime.slice(0, 10) }}</i>
        </li>
      </ul>
      <NoData v-else :type="3" />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import NoData from '@/components/NoData'
import { getNewsListByPage, getPlatTradeIndexList, getPlatTradeIndexChart } from '@/http/common'
export default {
  components: { NoData },
  data() {
    return {
      pnameArr: [
        { name: 'H型钢', val: 'H型钢' },
        { name: '碳结钢', val: '碳结钢' },
        { name: '槽钢', val: '槽钢' },
        { name: '方管', val: '方管' },
        { name: '工字钢', val: '工字钢' },
        { name: '带钢', val: '带钢' }
      ],
      timerArr: [
        { name: '近一月', val: '00' },
        { name: '近三月', val: '01' },
        { name: '近六月', val: '02' }
      ],
      newsArrList: [],
      activeIndex: 0,
      activePname: 0,
      tableData: [],
      chartData: [],
      formInline: {
        pageNum: 1,
        newsPlat: 3,
        pageSize: 10
      },
      echartsFormInline: {
        currentTime: '00',
        goodsName: 'H型钢',
        pageNum: 1,
        pageSize: 10
      }

    }
  },
  mounted() {
    this.getNewsList()
    this.getEchartsList()
  },
  methods: {
    getEchartsList() {
      getPlatTradeIndexList(this.echartsFormInline, res => {
        this.tableData = [...res.data.records]
        this.tableData.forEach(item => {
          item.price = item.price || 0
          item.goodsName = `${item.goodsName}/${item.goodsMaterialName}/${item.goodsSpecName}`
        })
      })
      getPlatTradeIndexChart(this.echartsFormInline, res => {
        this.chartData = [...res.data]
        const timeData = []; const moneyData = []
        this.chartData.forEach(item => {
          const timer = item.tradeDate.slice(5, item.tradeDate.length + 1) + '       '
          timeData.push(timer)
          moneyData.push(item.price || 0)
        })
        this.initEcharts(timeData, moneyData, this.echartsFormInline.goodsName)
      })
    },
    getNewsList() {
      getNewsListByPage(this.formInline, (res) => {
        this.newsArrList = res.data.pageData.filter(
          (item) =>
            item.newsPlatList.includes(1) || item.newsPlatList.includes(3)
        )
      })
    },
    // 折线图函数
    initEcharts(timeData, moneyData, goodsName) {
      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: timeData,
          textStyle: {
            color: '#999999',
            fontSize: 12
          },
          axisTick: {
            show: true, // 刻度
            lineStyle: { color: '#D9D9D9' }
          },
          axisLine: {
            show: false // 线条
          }
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          borderWidth: 0,
          formatter: function(a) {
            const html = `<p><span style='color:#999;font-size:12px'>${a[0].name}</span><br><span style='color:#666666;font-size:14px;'><i style='display:inline-block;height:6px;width:6px;border-radius:3px;background-color:#D31E0E;transform: translateY(-3px);margin-right:4px;'></i>${goodsName || ''}：${a[0].data}元/吨</span></p>`
            return html
          }
        },
        // legend: {
        //   data: ['支付金额'],
        //   top: '20px',
        //   right: '52px',
        //   itemHeight: 6,
        //   itemWidth: 12,
        //   icon: 'roundRect'
        // },
        title: {
          show: true,
          text: '元/吨',
          top: '60px',
          right: '10px',
          textStyle: {
            color: '#93959A',
            fontWeight: 500,
            fontSize: 13
          }
        },
        grid: {
          x: 46,
          y: 92,
          x2: 4,
          y2: 32
        },
        series: [
          {
            name: '支付金额',
            data: moneyData,
            type: 'line',
            // symbolSize: 6,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(211,30,14,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(211,30,14,0)'
                  }
                ],
                false
              )
            },
            lineStyle: {
              color: '#D31E0E',
              width: 1
            },
            itemStyle: {
              color: '#D31E0E'
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },
    // 点击时间的
    getIndex(item, index) {
      this.activeIndex = index
      this.echartsFormInline.currentTime = item.val
      this.getEchartsList()
    },
    // 点击品名的
    getPname(item, index) {
      this.activePname = index
      this.echartsFormInline.goodsName = item.val
      this.getEchartsList()
    }
  }
}
</script>

<style lang="scss">
  .trading-news-main-box.tradingIndexAndNewsPageBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px auto 0;
  width: $centerPlateWidth;
  // 滚动条
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f9f9f9;
  }

  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(253, 254, 255, 0.3);
    border-radius: 10px;
  }
  .right {
    .newsBox {
      padding: 8px 24px;
      height: 552px;
      overflow-y: auto;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 46px;
        i {
          font-style: normal;
          color: #999;
          font-size: 14px;
          text-align: right;
          flex: 0 0 86px;
        }
        .newsTitle {
          color: #333;
          font-size: 16px;
          padding-right: 24px;
          flex: 1;
          span {
            display: inline-block;
            height: 24px;
            width: 44px;
            color: $themeColors;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
            background-color: rgba(211, 30, 14, 0.08);
            margin-right: 8px;
          }
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      height: 74px;
      border-bottom: 1px solid #e7e7e7;
      box-sizing: border-box;
      .more {
        display: flex;
        align-items: center;
        color: #666;
        font-size: 14px;
        img {
          height: 16px;
          width: 16px;
        }
      }
      .name {
        display: flex;
        align-items: center;
        color: #000;
        font-size: 24px;
        img {
          height: 24px;
          width: 24px;
          margin-right: 4px;
        }
      }
    }
  }
  .left {
    .tableBox {
      padding: 4px 24px 0;
      .el-table--scrollable-x .el-table__body-wrapper {
        overflow-x: hidden;
      }
      .el-table__header .el-table__cell {
        padding: 6px 0;
        font-size: 14px;
        color: #999;
        font-weight: 500;
      }
      .el-table__body-wrapper .el-table__cell {
        padding: 11px 0;
        font-size: 16px;
        color: #333;
        font-weight: 500;
      }
      .el-table .el-table__cell > .cell {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .lineChart {
      position: relative;
      height: 290px;
      padding: 0 24px;
      .titles {
        position: absolute;
        top: 16px;
        width: 100%;
        z-index: 12;
        span {
          display: inline-block;
          margin: 0 12px;
          padding: 0 4px;
          height: 38px;
          line-height: 36px;
          box-sizing: border-box;
          border-bottom: 2px solid #fff;
          color: #666;
          font-size: 16px;
          cursor: pointer;
        }
        .active {
          color: $themeColors;
          border-bottom: 2px solid $themeColors;
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 24px 18px;
      border-bottom: 1px solid #e7e7e7;
      .timer {
        border: 1px solid #e7e7e7;
        display: flex;
        height: 28px;
        box-sizing: border-box;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #666;
          font-size: 14px;
          border-right: 1px solid #e7e7e7;
          width: 62px;
          cursor: pointer;
        }
        .active {
          color: $themeColors;
          background-color: rgba(246, 246, 246, 1);
        }
        span:last-of-type {
          border-right: 0;
        }
      }
      h3 {
        color: #000;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }
  }
  .left,
  .right {
    width: 592px;
    background-color: #fff;
    height: 655px;
    overflow: hidden;
  }
}
</style>
