<template>
  <div class="hotBuyPageBox hot-buy-main-box">
    <div class="title">
      <h3 class="left">
        <img src="@/assets/img/hot.png" alt="hot">热门求购
      </h3>
      <div class="right" @click="skip">
        更多<img src="@/assets/img/right.png" alt="right">
      </div>
    </div>
    <div class="hotProduct">
      <div v-for="(item,index) in listData" :key="item.id" target="_blank" class="list" @click="skip(item.id)">
        <!-- <img v-if="index===100" class="soldOut" src="@/assets/img/yixiajia.png" alt="下架"> -->
        <div class="limitBox">
          <!-- 上面的公司相关的 -->
          <div class="corporateInformation">
            <h4 :class="['name',index%3===1?'active1':'',index%3===2?'active2':'']">
              <span>{{ item.cmpName.slice(0,4) }}</span>
            </h4>
            <div class="pname">
              <h3 class="elp-1">
                {{ item.pName }}
              </h3>
              <p class="elp-1">
                采购量<i>{{ item.totalWeight }}</i>吨 共<i>{{ item.number }}</i>种
              </p>
            </div>
          </div>
          <!-- 各个参数的 -->
          <p class="item elp-1">
            <span>交货地点：</span>{{ item.receivingArea }}
          </p>
          <p class="item elp-1">
            <span>支付方式：</span>{{ item.paymentMethodWord }}
          </p>
          <p class="item elp-1">
            <span>求购单位：</span>{{ item.cmpName }}
          </p>
          <p class="item elp-1">
            <span>联系电话：</span>{{ item.phone }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goodsHallPagePurchaseHallGoods } from '@/http/wantToBuy.js'
export default {
  data() {
    return {
      formInline: {
        pageSize: 3,
        pageNum: 1
      },
      listData: [],
      paymentMethodList: [
        { id: '0', label: '先款后货' },
        { id: '1', label: '货到付款' },
        { id: '2', label: '账期支付' }
      ]
    }
  },
  mounted() {
    this.getWantToBuyHallPage()
  },
  methods: {
    skip(id) {
      sessionStorage.activeHomePath = '/buyHall/index'
      if (id.constructor !== String) {
        this.$router.push({ path: '/buyHall/index' })
        return
      }
      this.$router.push({ path: '/buyHall/wantBuyDetail', query: { id }})
    },
    getWantToBuyHallPage() {
      goodsHallPagePurchaseHallGoods(this.formInline, res => {
        this.listData = res.data.records
        this.listData.forEach(item => {
          item.pName = ''
          item.weight = ''
          item.number = ''
          if (item.tradeWantToBuyGoodsInfoReqs && item.tradeWantToBuyGoodsInfoReqs.length > 0) {
            item.tradeWantToBuyGoodsInfoReqs.forEach(val => {
              item.weight += Number(val.quantity)
            })
            item.pName = `${item.tradeWantToBuyGoodsInfoReqs[0].goodsTwoCategoryName} ${item.tradeWantToBuyGoodsInfoReqs[0].goodsMaterialName} ${item.tradeWantToBuyGoodsInfoReqs[0].goodsSpecName}`
            item.number = item.tradeWantToBuyGoodsInfoReqs.length
          }
          const text = []
          this.paymentMethodList.map((jtem) => {
            if ((item.paymentMethod || '').indexOf(jtem.id) !== -1) {
              text.push(jtem.label)
            }
          })
          item.paymentMethodWord = text.toString()
        })
      })
    }
  }
}
</script>

<style lang="scss">
.hot-buy-main-box.hotBuyPageBox {
  margin: 16px auto;
  width: $centerPlateWidth;
  background-color: #fff;
  .hotProduct {
    display: flex;
    align-items: center;
    .list {
      position: relative;
      width: 400px;
      height: 234px;
      border-right: 1px solid #e7e7e7;
      box-sizing: border-box;
      color: #333;
      cursor: pointer;
      .soldOut{
        position: absolute;
        right: 24px;
        top: 72px;
        height: 56px;
        width: 56px;
      }
      .limitBox {
        padding: 24px;
        .item{
          color: $mainFont;
          font-size: 16px;
          margin-bottom: 8px;
          span{
            width: 88px;
            display: inline-block;
            color: #666;
          }
        }
        .item:last-of-type{
          margin-bottom: 0;
        }
        .corporateInformation {
          display: flex;
          margin-bottom: 24px;
          .pname{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 48px;
            p{
              color: #666;
              font-size: 14px;
              i{
                font-style: normal;
                color: $themeColors;
              }
            }
            h3{
              color: #333;
              font-size: 20px;
              margin-bottom: 4px;
            }
          }
          .name {
            position: relative;
            height: 48px;
            width: 48px;
            margin-right: 16px;
            color: #fff;
            background-color: rgba(211, 30, 14, 0.4);
            font-size: 16px;
            span{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-46.5%,-50%);
              display: inline-block;
              text-align: center;
              height: 38px;
              width: 36px;
              line-height: 1.2;
              letter-spacing:2px;
            }
          }
          .active1{
            background-color: rgba(245,112,15,0.38);
          }
          .active2{
            background-color: rgba(35,135,227,0.4);
          }
        }
      }
    }
    .disabled{
      color: #999!important;
      .corporateInformation{
        .pname{
          p{
            color: #999!important;
            i{
              color: #999!important;
            }
          }
          h3{
            color: #999!important;
          }
        }
      }
      .item{
        color: #999!important;
        span{
          color: #999!important;
        }
      }
    }
    .list:hover {
      border: 1px solid $themeColors !important;
      box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.05),
        0 8px 24px 0 rgba(0, 0, 0, 0.04), 0 4px 10px 0 rgba(0, 0, 0, 0.08);
    }
    .list:last-of-type {
      border-right: 0;
    }
  }
  .title {
    padding: 0 24px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    box-sizing: border-box;
    .right {
      display: flex;
      align-items: center;
      color: #666;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .left {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 24px;
      img {
        height: 24px;
        width: 24px;
        margin-right: 4px;
      }
    }
  }
}
</style>
