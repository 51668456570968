<template>
  <div class="slide-bg">
    <div class="slideshowPageBox">
      <div class="center-carousel">
        <el-carousel>
          <el-carousel-item v-for="item in bannerList" :key="item.img">
            <h3 class="small">
              <img :src="item.img" alt="">
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="left">
        <ul class="productList">
          <li @mouseover="getPname(1, goodsCategoryList)" @mouseleave="isHover = false">
            <div>
              <p class="product-title">
                <i class="icon steel-icon" />
                钢材分类
              </p>
              <p class="product-box">
                <span v-for="item in goodsCategoryList" :key="item.code" class="product-item">{{ item.goodsName }}</span>
              </p>
            </div>
          </li>
          <li @mouseover="getPname(2, plasticCategoryList)" @mouseleave="isHover = false">
            <div>
              <p class="product-title">
                <i class="icon plastic-icon" />
                塑料分类
              </p>
              <p class="product-box">
                <span v-for="item in plasticCategoryList" :key="item.code" class="product-item">{{ item.goodsName }}</span>
              </p>
            </div>
          </li>
        </ul>
        <!-- 当hover时才出现的弹窗 -->
        <div :class="['productListSubclass',isHover ? 'sub-active':'']" @mouseover="isHover = true" @mouseleave="isHover = false">
          <!-- {{ productArr }} -->
          <div class="subclass">
            <div v-for="item in productArr" :key="item.code">
              <span class="label">{{ item.goodsName }}：</span>
              <div class="produc-item">
                <span v-for="jtem in item.children" :key="jtem.code" @click="gotoGoodsList(jtem)">{{ jtem.goodsName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top-box">
          <img v-if="!isLogin" class="headPortrait" src="@/assets/img/touxiang.png" alt="touxiang">
          <img v-else class="headPortrait" src="@/assets/image/touxiang.png" alt="touxiang">
          <h3 class="welcome elp-1">
            Hi，<span v-if="!isLogin">你好!</span><span v-else>{{ userInfo.userName|| userInfo.orgName }}</span>
          </h3>
          <!-- 按钮组合 -->
          <div v-if="!isLogin" class="buttonCombination">
            <router-link :to="{path:'/login'}">
              请登录
            </router-link>
            <router-link :to="{path:'/register'}">
              免费注册
            </router-link>
          </div>
          <!-- 订单状态 -->
          <div v-else class="slogan slogan2">
            <div class="list" @click="jumpOrder('01,06')">
              <p>{{ orderSummary.orderWaitPay }}</p>
              <span>待支付</span>
            </div>
            <div class="list" @click="jumpOrder('03,04,05')">
              <p>{{ orderSummary.orderWaitSign }}</p>
              <span>待签署</span>
            </div>
            <div class="list" @click="jumpOrder('08')">
              <p>{{ orderSummary.orderWaitPick }}</p>
              <span>待提货</span>
            </div>
          </div>
        </div>

        <!-- 平台标语 -->
        <div class="slogan slogan3">
          <div class="list" @click="publishPurchase">
            <i class="icon icon1" />
            <span>快速求购</span>
          </div>
          <div class="list" @click="jumpOrder('')">
            <i class="icon icon2" />
            <span>订单中心</span>
          </div>
          <div class="list" @click="financialService">
            <i class="icon icon3" />
            <span>金融服务</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goodsGetGoodsCategoryList } from '@/http/hangOut'
import { getOrderBuyerSummary, getOrderSellerSummary } from '@/http/order'
import { creditValidateCredit, cmpOpenSystem } from '@/http/financing'
export default {
  data() {
    return {
      activeIndex: null,
      isLogin: false,
      productArr: [],
      goodsCategoryList: [{}],
      plasticCategoryList: [{}],
      token: null,
      userInfo: {},
      isHover: false,
      orderSummary: {},
      bannerList: [
        { img: require(`@/assets/img/home_banner_two.png`) },
        { img: require(`@/assets/img/home_banner_one.png`) }
      ]
    }
  },
  mounted() {
    this.token = localStorage.getItem(
      `mallFrontDeskToken${this.$store.state.userCmpRole}`
    )
    this.getGoodsGetGoodsCategoryList()
    if (
      localStorage.getItem(`mallFrontDeskToken${this.$store.state.userCmpRole}`)
    ) {
      this.isLogin = true
      this.userInfo = JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)) || {}
    }
    if (this.token && this.token !== 'null' && this.token !== null) {
      this.getOrderSummary()
    }
  },
  methods: {
    getPname(index, item) {
      // 如果总在一个项目上移动  就终止他
      if (this.activeIndex === index && this.isHover) return
      this.activeIndex = index
      this.isHover = true
      this.productArr = item && item.length > 0 ? [...item] : []
    },
    // 查询品类
    getGoodsGetGoodsCategoryList() {
      goodsGetGoodsCategoryList((res) => {
        if (res.data && res.data.length > 0) {
          this.goodsCategoryList = res.data[0].children
          this.plasticCategoryList = res.data && res.data[1] && res.data[1].children
        }
      })
    },
    // 发布求购
    publishPurchase() {
      if (!this.token || !this.token.length) {
        this.$message.warning('还未登录， 请登录')
        this.$router.push({ path: '/login' })
        return
      }
      switch (this.userInfo.cmpState) {
        case '01':
          this.infoMakeup()
          return
        case '-1':
          this.infoMakeup()
          return
        default:
        {
          if (this.$store.state.userCmpRole !== '02') return this.$message.warning('账号所属企业暂无买家权限')
          sessionStorage.activeHomePath = '/buyHall/wantBuyAdd'
          const routeData = this.$router.resolve({ path: '/buyHall/wantBuyAdd' })
          window.open(routeData.href, '_blank')
          break
        }
      }
    },
    infoMakeup() {
      this.$confirm(
        `亲爱的${this.userInfo.userName || this.userInfo.orgName}用户, 为了您能正常使用平台提供的功能，请尽快完善公司信息`,
        '信息未完善',
        {
          confirmButtonText: '立即完善',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/orgStructure/enterpriseInformation`, '_blank')
      })
        .catch(() => {})
    },
    // 获取待支付 待签署等
    getOrderSummary() {
      let api = ''
      if (this.$store.state.userCmpRole === '02') api = getOrderBuyerSummary
      if (this.$store.state.userCmpRole === '01') api = getOrderSellerSummary
      api(res => {
        this.orderSummary = res.data
      })
    },
    // 品名跳转列表
    gotoGoodsList(item) {
      this.$router.push({ path: '/spotHall/index', query: { goodsType: item.code.slice(0, 2), goodsName: item.goodsName, code: item.code }})
    },
    // 订单中心
    jumpOrder(state) {
      if (!this.token || !this.token.length) {
        this.$message.warning('还未登录， 请登录')
        this.$router.push({ path: '/login' })
        return
      }
      const userCmpRole = this.$store.state.userCmpRole
      switch (this.userInfo.cmpState) {
        case '01':
          window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${userCmpRole}&path=/orgStructure/enterpriseInformation`, '_blank')
          return
        case '-1':
          window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${userCmpRole}&path=/orgStructure/enterpriseInformation`, '_blank')
          return
        default:
        {
          const path = this.$store.state.userCmpRole === '02' ? '/orderManage/orderManageBuyersIndex' : '/orderManage/orderManageSellerIndex'
          window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${userCmpRole}&path=${path}&orderState=${state}`, '_blank')
          break
        }
      }
    },
    // 金融服务
    financialService() {
      if (!this.token || !this.token.length) {
        this.$message.warning('还未登录， 请登录')
        this.$router.push({ path: '/login' })
        return
      }
      // const isTest = location.pathname.indexOf('-test') >= 0
      const formData = {
        cmpCode: this.userInfo.cmpId,
        cmpRole: this.userInfo.cmpRole
      }
      creditValidateCredit((res) => {
        if (res.data === true) {
          cmpOpenSystem(formData, resq => {
            if (resq.msg) {
              window.open(
                `${this.$scfPlatformUrl}login?token=${resq.msg}`,
                '_blank'
              )
            }
          })
          return
        }
        window.open(
          `${this.$scfPlatformUrl}login`,
          '_blank'
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-bg {
  width: 100%;
  height: 440px;
  background: url('../../../assets/image/banner_bg.png') no-repeat top left;
  display: flex;
  align-items: center;
}
.slideshowPageBox {
  height: 100%;
  width: 100%;
  position: relative;
  .right{
    position: absolute;
    right: calc((100% - 1200px)/2);
    top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    width: 236px;
    .top-box {
      width: 100%;
      height: 246px;
      margin-bottom: 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 40px 24px;
      box-sizing: border-box;;
    }
    .slogan{
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333;
      font-size: 12px;
      background-color: #fff;
      .list{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        p{
          color: $themeColors;
          font-size: 20px;
          margin-bottom: 2px;
        }
      }
      &.slogan3 {
        .list:hover {
          color: $themeColors;
          .icon1 {
            background: url('../../../assets/img/icon2_a.png') no-repeat top center;
            background-size: contain;
          }
          .icon2 {
            background: url('../../../assets/img/icon3_a.png') no-repeat top center;
            background-size: contain;
          }
          .icon3 {
            background: url('../../../assets/img/icon1_a.png') no-repeat top center;
            background-size: contain;
          }
        }
        .icon {
           height: 24px;
           width: 24px;
           margin-bottom: 8px;
           display: inline-block;
        }
        .icon1 {
          background: url('../../../assets/img/icon2.png') no-repeat top center;
          background-size: contain;
        }
        .icon2 {
          background: url('../../../assets/img/icon3.png') no-repeat top center;
          background-size: contain;
        }
        .icon3 {
          background: url('../../../assets/img/icon1.png') no-repeat top center;
          background-size: contain;
        }
      }
    }
    .buttonCombination{
      margin: 24px 0;
      border-bottom: 1px solid #E7E7E7;
      a{
        display: inline-block;
        width: 90px;
        height: 40px;
        border: 1px solid $themeColors;
        text-align: center;
        line-height: 38px;
        box-sizing: border-box;
        color: $themeColors;
        font-size: 16px;
      }
      a:first-of-type{
        background-color: $themeColors;
        color: #fff;
        margin-right: 8px;
      }
    }
    .welcome{
      padding: 16px 0 29px;
      color: #333;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      width: calc(100% - 48px);
    }
    .headPortrait{
      height: 64px;
      width: 64px;
    }
  }
  .left{
    position: absolute;
    left: calc((100% - 1200px)/2);
    top: 0;
    width: 236px;
    height: 100%;
    z-index: 10;
    background: rgba(0,0,0,0.5);
    color: #fff;
    .productList {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 0;
      li {
        width: 100%;
        font-size: 14px;
        box-sizing: border-box;
        cursor: pointer;
        > div {
          padding: 16px 24px;
          &:hover {
            background-color: #fff;
            color: #333333;
            .steel-icon {
              background: url('../../../assets/banner/category1_a.png') no-repeat top left;
              background-size: 100% 100%;
            }
            .plastic-icon {
              background: url('../../../assets/banner/category2_a.png') no-repeat top left;
              background-size: 100% 100%;
            }
          }
        }
        .product-box {
          padding-left: 32px;
        }
        &:first-child {
          background-size: 100% 100%;
          padding-bottom: 30px;
          border-bottom: 1px solid rgba(255,255,255,0.24);
          .product-item {
            display: inline-block;
            min-width: 40px;
            margin-bottom: 6px;
          }
        }
        &:last-child {
          background-size: 100% 100%;
          padding-top: 16px;
           .product-item {
            display: inline-block;
            min-width: 56px;
            margin-right: 10px;
            margin-bottom: 6px;
          }
        }
        .product-title {
          font-size: 20px;
          margin-bottom: 14px;
          display: flex;
          align-items: center;
          .icon {
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 8px;
          }
          .steel-icon {
            background: url('../../../assets/banner/category1.png') no-repeat top left;
            background-size: 100% 100%;
          }
          .plastic-icon {
            background: url('../../../assets/banner/category2.png') no-repeat top left;
            background-size: 100% 100%;
          }
        }

      }
    }
    .active {
      li{border-right: 1px solid #E7E7E7;}
    }

    .productListSubclass{
      position: absolute;
      top: 0;
      left: 236px;
      width: 0;
      z-index: 999;
      height: 100%;
      background-color: #fff;
      transition: 0.2s all;
      box-shadow: 4px -6px 14px 2px rgba(0,0,0,0.05), 13px 4px 10px 1px rgba(0,0,0,0.06), 1px 5px 5px -3px rgba(0,0,0,0.1);
      overflow: hidden;
      box-sizing: border-box;
      &.sub-active {
        width: 964px;
        overflow-y: auto;
        padding: 20px 24px;
        box-sizing: border-box;
      }
      .subclass{
        width: 700px;

        > div {
          display: flex;
          justify-content: flex-start;
        }
        .label {
          color: $mainFont;
          font-size: 16px;
          cursor: default;
          flex-shrink:0;
        }
        .produc-item {
          flex: 1;
          overflow:hidden;
          text-overflow:ellipsis;
          display:-webkit-box;    //将对象作为弹性伸缩盒子模型显示。
          -webkit-box-orient:vertical;  // 从上到下垂直排列子元素
          -webkit-line-clamp:1;
          span{
            display: inline-block;
            color: $fontColor5;
            font-size: 14px;
            margin-bottom: 20px;
            margin-right: 12px;
            cursor: pointer;
            &:hover{
              color: $themeColors;
            }
          }
        }

      }
      .title{
        padding: 24px 24px 16px;
        color: #000;
        font-size: 20px;
      }
    }
  }
  .center-carousel{
    position: relative;
    flex: 1;
    height: 100%;
    /deep/ .el-carousel {
      height: 100%;
      .el-carousel__container {
        height: 100%;
        .small  {
          height: 100%;
        }
      }
    }
    img{
      height: 100%;
      width: 100%;
    }
  }
}
// 滚动条
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f9f9f9;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(253, 254, 255, 0.3);
  border-radius: 10px;
}
</style>
